<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
          "
        >
          <span class="h6 font-weight-bold text-truncate"
            >Produk Grup - Tambah</span
          >
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
            <i class="fa fa-home text-main"></i>
          </a>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row d-none">
          <div class="col-12">
            <div
              class="
                alert alert-danger
                bg-light
                mt-3
                mb-0
                alert-dismissible
                fade
                show
              "
              role="alert"
            >
              <button type="button" class="close" data-dismiss="alert">
                &times;
              </button>
              <strong>Alert!</strong> You should
              <a
                href="javascript:void(0);"
                class="alert-link"
                title="Click to go to this message"
                >read this message</a
              >.
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-4 mb-5">
          <div class="col-12">
            <Notif />
            <Form
              @submit="tambah"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="POST"
              class="mb-3 needs-validation"
            >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="nama"
                      >Nama Produk Grup:
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      id="nama"
                      v-model="state.form.name"
                      placeholder="Masukkan nama..."
                      name="name"
                      autofocus
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="sel1"
                      >Status:<span class="text-danger ml-1">*</span></label
                    >
                    <Field
                      name="status"
                      as="select"
                      class="form-control"
                      :class="{ 'is-invalid': errors.status }"
                      v-model="state.form.status"
                    >
                      <option :value="1">Aktif</option>
                      <option :value="0">Inaktif</option>
                    </Field>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.status }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <label for="foto"
                    >Foto Produk Grup:<span class="text-danger ml-1"
                      >*</span
                    ></label
                  >
                  <div class="custom-file mb-3">
                    <input
                      type="file"
                      ref="fileInput"
                      class="custom-file-input form-control"
                      :class="{ 'is-invalid': isPhoto }"
                      id="upload"
                      accept="image/*"
                      name="foto"
                      @input="pickFile"
                      @change="onFileChange"
                    />
                    <label
                      class="custom-file-label"
                      for="upload"
                      id="upload-label"
                      >Pilih gambar...</label
                    >
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback" v-if="isPhoto">Foto tidak boleh kosong</div>
                  </div>
                  <div class="image-area mb-4 w-50 mx-auto p-3">
                    <img
                      v-if="state.url" :src="state.url"
                      alt="image"
                      class="img-fluid rounded shadow-sm mx-auto d-block"
                    />
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .form-row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button type="submit" class="btn btn-main" title="Tambah" :disabled="state.loading">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                    <i class="fa fa-plus mr-1" v-else></i>Tambah
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light ml-1"
                    title="Reset isian"
                  >
                    <i class="fas fa-sync-alt"></i>Reset
                  </button>
                  <a href="#" @click="batal" class="btn btn-light ml-1" title="Batal"
                    ><i class="fas fa-times"></i>Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";
import { useRouter } from "vue-router";
import { Field, Form } from "vee-validate";
import { reactive, onMounted } from "vue";
import * as Yup from "yup";
import axios from 'axios';
import Notif from '../../../../helpers/Notif.vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const $swal = useVueSweetAlert2();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      form: {
        name: "",
        status: 1,
        photo: "",
      },
      url: '',
      loading: false,
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/produk-group" });
          }
        });
    };
    const tambah = () => {
      state.loading = true;
      axios.post('/api/seller/produk-group', state.form).then((res) => {
        if(res.data.success){
          $swal.fire({
            icon: "success",
            title: "Selamat",
            text: "Group Produk Berhasil Ditambahkan!",
          });
          router.push({ path: "/jual/produk-group" });
        }
        state.loading = false;
      }, () => {
        state.loading = false;
      })
    };
    onMounted(() => {
      store.dispatch("error/setDefault");
    })
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      status: Yup.string().required("Pilih Salah satu"),
    });
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      batal,
      state,
      schema,
      tambah,
      back
    };
  },
  components: {
    Field,
    Form,
    Notif,
  },
  methods: {
    pickFile () {
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.state.form.photo = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.state.url = URL.createObjectURL(file);
    }
  },
  computed: {
    isPhoto(){
      return this.state.form.photo === '';
    }
  }
};
</script>